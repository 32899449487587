@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  position: relative;
}

.drift-frame-controller {
  position: unset;
}

.anti-flicker,
.anti-flicker * {
  visibility: hidden !important;
  opacity: 0 !important;
}

@layer base {
  html {
    @apply font-sans text-teal-5 tracking-[0.25px];
  }
  body {
    width: auto;
    overflow-x: hidden;
  }

  .large-container {
    @apply px-5 sm:px-10 xl:container;
  }

  .base-container {
    @apply px-5 sm:px-10 xl:max-w-[1440px] mx-auto;
  }

  .medium-container {
    @apply px-5 sm:px-10 lg:w-[1133px] xl:w-[1200px] mx-auto;
  }

  .small-container {
    @apply px-5 sm:px-10 w-full lg:w-[1060px] mx-auto;
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* remove image gray border while loading on Safari  */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}
